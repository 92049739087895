<template>
    <div>

        <Banner></Banner>
        <PropertyType></PropertyType>
        <!-- <BestDeal></BestDeal> -->
        <Featured></Featured>
        <OurPartner></OurPartner>

    </div>
</template>


<script>
export default {
    name: 'Home',
    components: {
        // Lazy Loading Components
        Banner: () => import('./Banner.vue'),
        PropertyType: () => import('./PropertyType.vue'),
        //BestDeal: () => import('./BestDeal.vue'),
        Featured: () => import('./Featured.vue'),
        // Testimonials: () => import('./Testimonials.vue'),
        OurPartner: () => import('./OurPartner.vue'),
    },
    data(){
        return {
            //
        }
    },
    mounted() {},
    created() {},
    methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
